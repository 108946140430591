<template>
  <b-card-code>
    <b-modal id="changePrivateMessage" title="Státusz állítása" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <b-col lg="12">
          <b-form-group
            label="Megjegyzés"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="title"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group
            label="Kire vonatkozik"
            label-for="target_name"
          >
            <b-form-input
              id="target_name"
              v-model="target_name"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12" class="justify-content-end d-flex my-2">
          <b-button size="sm" variant="danger" @click="$bvModal.hide('changePrivateMessage')">
            Mégsem
          </b-button>
          <b-button class="ml-1" size="sm" variant="success" @click="updatePrivateMessage()">
            Rögzítés
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <div class="row justify-space-between mb-2 align-items-center">
      <div class="col-lg-2">
        <h4>Szabadságok</h4>
      </div>
      <div class="col-lg-10 justify-content-end row">
        <button class="btn btn-primary mr-0 mr-md-2 col-12 col-lg-2 mt-2" @click="$router.push('/freedays/year/list')">Éves nézet</button>
        <button class="btn btn-primary mr-0 mr-md-2 col-12 col-lg-2 mt-2" v-if="checkRole('leader_freeday')" @click="$router.push('/freedays/own/leader')">Még eldöntendő</button>
        <button class="btn btn-primary mr-0 mr-md-2 col-12 col-lg-2 mt-2" v-if="checkRole('foreman_freeday')" @click="$router.push('/freedays/own/foreman')">Még eldöntendő</button>
        <button class="btn btn-primary mr-0 mr-md-2 col-12 col-lg-2 mt-2" @click="openFreeDayRequest()">Új létrehozás</button>
        <button class="btn btn-primary col-12 col-lg-2 mt-2" @click="openFilter()">Szűrés</button>
      </div>
      <div class="col-lg-12 text-right mt-2">
        <span class="ml-1 badge badge-primary" v-if="filter.start_date !== ''">
          {{ filter.start_date}}
          <feather-icon
            style="cursor:pointer"
            @click="filter.start_date = '';runFilter()"
            icon="MinusCircleIcon"
            size="26"
            class="text-light"
          />
        </span>
        <span class="ml-1 badge badge-primary" v-if="filter.end_date !== ''">
          {{ filter.end_date}}
          <feather-icon
            style="cursor:pointer"
            class="text-light"
            size="26"
            icon="MinusCircleIcon"
            @click="filter.end_date = '';runFilter()"
          />
        </span>
        <span class="ml-1 badge badge-primary">
          <span v-if="filter.type !== '%'">{{ filter.type }} </span>
          <span v-else>Összes szabadság típus </span>
          <feather-icon
            v-if="filter.type !== '%'"
            style="cursor:pointer"
            @click="filter.type = '%';runFilter()"
            icon="MinusCircleIcon"
            size="26"
            class="text-light"
          />
        </span>
        <span class="ml-1 badge badge-primary" v-if="filter.employee_id !== ''">
          {{ getEmployeeNameById(filter.employee_id) }}
          <feather-icon
            v-if="filter.employee_id !== '%'"
            style="cursor:pointer"
            class="text-light"
            @click="filter.employee_id = '%';runFilter()"
            icon="MinusCircleIcon"
            size="26"
          />
        </span>
        <span class="ml-1 badge badge-primary" v-if="filter.archive == 0">
          Aktív
        </span>
        <span class="ml-1 badge badge-primary" v-if="filter.archive == 1">
          Archivált
        </span>
        <span class="ml-1 badge badge-primary" v-if="filter.archive == '%'">
          Összes listázása
        </span>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <div
          v-if="props.column.field === 'leader_accepted'"
          class="text-center"
          :title="props.row.leader_name"
        >
          <feather-icon
            v-if="props.row.leader_accepted == 1"
            icon="CheckCircleIcon"
            size="26"
            class="text-success"
          />
          <feather-icon
            v-else-if="props.row.leader_accepted == 2"
            icon="MinusCircleIcon"
            size="26"
            class="text-danger"
          />
          <feather-icon
            v-else
            icon="AlertCircleIcon"
            size="26"
            class="text-warning"
          />
        </div>

        <div v-else-if="props.column.field === 'archive'">
          <span v-if="props.row.archive">Arcivált</span>
          <span v-else>Aktív</span>
        </div>

        <div
          v-else-if="props.column.field === 'foreman_accepted'"
          class="text-center"
          :title="props.row.foreman_name"
        >
          <feather-icon
            v-if="props.row.foreman_accepted == 1"
            icon="CheckCircleIcon"
            size="26"
            class="text-success"
          />
          <feather-icon
            v-else-if="props.row.foreman_accepted == 2"
            icon="MinusCircleIcon"
            size="26"
            class="text-danger"
          />
          <feather-icon
            v-else
            icon="AlertCircleIcon"
            size="26"
            class="text-warning"
          />
        </div>

        <div
          v-else-if="props.column.field === 'foreman_name'"
        >
          {{ props.row.foreman_name }}
        </div>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item v-show="checkRole('foreman_freeday') && props.row.foreman_accepted != 1" @click="changeForeman(props.row.id)">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                <span>Elfogadás</span>
              </b-dropdown-item>
              <b-dropdown-item v-show="checkRole('foreman_freeday') && props.row.foreman_accepted != 2" @click="changeForemanDisable(props.row.id)">
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span>Elutasítás</span>
              </b-dropdown-item>
              <b-dropdown-item v-show="checkRole('leader_freeday') && props.row.leader_accepted != 1" @click="changeLeader(props.row.id)">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                />
                <span>Elfogadás</span>
              </b-dropdown-item>
              <b-dropdown-item v-show="checkRole('leader_freeday') && props.row.leader_accepted != 2" @click="changeLeaderDisable(props.row.id)">
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span>Elutasítás</span>
              </b-dropdown-item>
              <b-dropdown-item @click="edit(props.row.id)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Szerkesztés</span>
              </b-dropdown-item>
              <b-dropdown-item @click="archive(props.row.id)">
                <feather-icon
                  icon="BoxIcon"
                  class="mr-50"
                />
                <span>Archiv státusz változtatása</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteFreeday(props.row.id)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Törlés</span>
              </b-dropdown-item>
              <!--<b-dropdown-item @click="deleteComment(props.row.id)">
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span>Törlés</span>
              </b-dropdown-item>-->
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal id="free_day_request" ref="free_day_request" hide-footer title="Szabadság igény rögzítése">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Szabadság típusa"
          >
            <b-form-select
              v-model="free_day_request.type"
              :options="free_day_request_types_create"
              label="Szabadság típusa"
              @change="alert('XD')"
            />
          </b-form-group>

          <b-form-group
            label="Alkalmazott"
          >
            <b-form-select
              v-model="free_day_request.employee_id"
              @change="free_day_request.timeStart = null; free_day_request.timeEnd = null"
              :options="employees_create"
              label="Alkalmazott"
              text-field="name"
              value-field="id"
              multiple
              style="height: 200px;"
            />
            <small>Ctrl + katt több kijelöléséhez</small>
          </b-form-group>

          <b-form-group
            label="Építővezető"
          >
            <b-form-select
              v-model="free_day_request.foreman_id"
              :options="foreman_list"
              label="Építővezető"
              text-field="name"
              value-field="id"
            />
          </b-form-group>

          <b-form-group
            label="Szabadság kezdete"
          >
            <b-form-input
              v-model="free_day_request.start_date"
              type="date" max="9999-12-31"
              label="Szabadság kezdete"
            />
          </b-form-group>

          <b-form-group
            label="Szabadság vége"
          >
            <b-form-input
              v-model="free_day_request.end_date"
              type="date" max="9999-12-31"
              label="Szabadság vége"
            />
          </b-form-group>

          <b-form-group
            v-if="free_day_request.type === 'Később érkezett' || free_day_request.type === 'Hamarabb elment'"
            label="Munka kezdete"
          >
            <b-form-input
              v-model="free_day_request.timeStart"
              type="time"
              label="Munka kezdete"
            />
          </b-form-group>

          <b-form-group
            v-if="free_day_request.type === 'Később érkezett' || free_day_request.type === 'Hamarabb elment'"
            label="Munka vége"
          >
            <b-form-input
              v-model="free_day_request.timeEnd"
              type="time"
              label="Munka vége"
            />
          </b-form-group>

          <b-form-group
            label="Megjegyzés"
          >
            <b-form-input
              v-model="free_day_request.comment"
              type="textarea"
              label="Megjegyzés"
            />
          </b-form-group>
          <p class="text-right mt-2">
            <button class="btn btn-primary" @click="createFreeDay()">Rögzítés</button>
          </p>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="update_free_day_request" ref="update_free_day_request" hide-footer title="Szabadság igény szerkesztése">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Szabadság típusa"
          >
            <b-form-select
              v-model="update_free_day_request.type"
              :options="free_day_request_types_create"
              label="Szabadság típusa"
            />
          </b-form-group>

          <b-form-group
            label="Alkalmazott"
          >
            <b-form-select
              v-model="update_free_day_request.employee_id"
              :options="employees_create"
              label="Alkalmazott"
              text-field="name"
              value-field="id"
            />
          </b-form-group>

          <b-form-group
            label="Építővezető"
          >
            <b-form-select
              v-model="update_free_day_request.foreman_id"
              :options="foreman_list"
              label="Építővezető"
              text-field="name"
              value-field="id"
            />
          </b-form-group>

          <b-form-group
            label="Szabadság kezdete"
          >
            <b-form-input
              v-model="update_free_day_request.start_date"
              type="date" max="9999-12-31"
              label="Szabadság kezdete"
            />
          </b-form-group>

          <b-form-group
            label="Szabadság vége"
          >
            <b-form-input
              v-model="update_free_day_request.end_date"
              type="date" max="9999-12-31"
              label="Szabadság vége"
            />
          </b-form-group>

          <b-form-group
            v-if="update_free_day_request.type === 'Később érkezett' || update_free_day_request.type === 'Hamarabb elment'"
            label="Munka kezdete"
          >
            <b-form-input
              v-model="update_free_day_request.timeStart"
              type="time"
              label="Munka kezdete"
            />
          </b-form-group>

          <b-form-group
            v-if="update_free_day_request.type === 'Később érkezett' || update_free_day_request.type === 'Hamarabb elment'"
            label="Munka vége"
          >
            <b-form-input
              v-model="update_free_day_request.timeEnd"
              type="time"
              label="Munka vége"
            />
          </b-form-group>

          <b-form-group
            label="Megjegyzés"
          >
            <b-form-input
              v-model="update_free_day_request.comment"
              type="textarea"
              label="Megjegyzés"
            />
          </b-form-group>
          <p class="text-right mt-2">
            <button class="btn btn-primary" @click="updateFreeDay()">Frissítés</button>
          </p>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="filter_modal" ref="filter_modal" hide-footer title="Szűrés">
      <b-form-group
        label="Kezdő dátum"
      >
        <b-form-input
          v-model="filter.start_date"
          type="date" max="9999-12-31"
          label="Kezdő dátum"
        />
      </b-form-group>
      <b-form-group
        label="Vég dátum"
      >
        <b-form-input
          v-model="filter.end_date"
          type="date" max="9999-12-31"
          label="Vég dátum"
        />
      </b-form-group>
      <b-form-group
        label="Alkalmazott"
      >
        <b-form-select
          v-model="filter.employee_id"
          :options="employees"
          label="Alkalmazott"
          text-field="name"
          value-field="id"
        />
      </b-form-group>
      <b-form-group
        label="Szabadság típusa"
      >
        <b-form-select
          v-model="filter.type"
          :options="free_day_request_types"
          label="Szabadság típusa"
        />
      </b-form-group>
      <b-form-group
        label="Archivált"
      >
        <b-form-select
          v-model="filter.archive"
          :options="archiveStatus"
          label="Archivált"
        />
      </b-form-group>
      <p class="text-center">
        <button class="btn btn-primary mt-1" @click="runFilter()">Szűrés</button>
      </p>
    </b-modal>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Calendar from 'v-year-calendar';
import axios from 'axios'
import Swal from 'sweetalert2'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BButton,
    Calendar,
  },
  data() {
    return {
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Vezető',
          field: 'leader_accepted',
          filterOptions: {
            enabled: false,
            placeholder: 'Státusz',
          },
        },
        {
          label: 'Építés vezető',
          field: 'foreman_accepted',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés megjegyzésben',
          },
        },
        {
          label: 'Alkalmazott',
          field: 'employee_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Szabadnap kezdete',
          field: 'start_date',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Szadnap vége',
          field: 'end_date',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Kezdés időpontja',
          field: 'timeStart',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Végzés időpontja',
          field: 'timeEnd',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Típus',
          field: 'type',
          filterOptions: {
            enabled: true,
            placeholder: 'Típusa',
          },
        },
        {
          label: 'Komment',
          field: 'comment',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés szövegben',
          },
        },
        {
          label: 'Építés vezető',
          field: 'foreman_name',
          filterOptions: {
            enabled: false,
            placeholder: 'Keresés szövegben',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      archiveStatus: [
        {
          value: '%',
          text: 'Mind',
        },
        {
          value: false,
          text: 'Nem',
        },
        {
          value: true,
          text: 'Igen',
        },
      ],
      employees: [],
      employees_create: [],
      rows: [],
      searchTerm: '',
      title: '',
      target_name: '',
      selected_id: '',
      filter: {
        start_date: '',
        end_date: '',
        employee_id: '%',
        type: '%',
        archive: false,
        payment: '%',
      },
      foreman_list: [],
      free_day_request: {
        type: '',
        foreman_id: '',
        employee_id: '',
        start_date: '',
        end_date: '',
        comment: '',
        payment: 'Havi',
        timeStart: null,
        timeEnd: null,
      },
      update_free_day_request: {
        type: '',
        foreman_id: '',
        employee_id: '',
        start_date: '',
        end_date: '',
        comment: '',
        payment: 'Havi',
        timeStart: '',
        timeEnd: '',
      },
      free_day_request_types: [
        {
          text: 'Összes',
          value: '%',
        },
        {
          text: 'Szabadság',
          value: 'Szabadság',
        },
        {
          text: 'Táppénz',
          value: 'Táppénz',
        },
        {
          text: 'Szabadnap',
          value: 'Szabadnap',
        },
        {
          text: 'Igazolatlan',
          value: 'Igazolatlan',
        },
        {
          text: 'Később érkezett',
          value: 'Később érkezett',
        },
        {
          text: 'Hamarabb elment',
          value: 'Hamarabb elment',
        },
        {
          text: 'Fizetetlen',
          value: 'Fizetetlen',
        },
        {
          text: 'Táppénz (OEP)',
          value: 'Táppénz (OEP)',
        },
      ],
      free_day_request_types_create: [
        {
          text: 'Szabadság',
          value: 'Szabadság',
        },
        {
          text: 'Táppénz',
          value: 'Táppénz',
        },
        {
          text: 'Szabadnap',
          value: 'Szabadnap',
        },
        {
          text: 'Igazolatlan',
          value: 'Igazolatlan',
        },
        {
          text: 'Később érkezett',
          value: 'Később érkezett',
        },
        {
          text: 'Hamarabb elment',
          value: 'Hamarabb elment',
        },
        {
          text: 'Fizetetlen',
          value: 'Fizetetlen',
        },
        {
          text: 'Táppénz (OEP)',
          value: 'Táppénz (OEP)',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-info',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-success',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.runFilter()
    this.getEmployees()
    this.getForemanList()
    this.getEmployeeWithOutAll()
  },
  methods: {
    runFilter() {
      axios({
        method: 'post',
        url: 'freeday/filter',
        data: this.filter,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.rows = res.data
        this.$refs.filter_modal.hide()
      })
    },
    openFilter() {
      this.$refs.filter_modal.show()
    },
    changeLeaderDisable(id) {
      axios({
        method: 'get',
        url: `freeday/change-leader-status-disable/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Státusz sikeresen állítva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.runFilter()
      })
    },
    changeForemanDisable(id) {
      axios({
        method: 'get',
        url: `freeday/change-foreman-status-disable/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Státusz sikeresen állítva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.runFilter()
      })
    },
    changeForeman(id) {
      axios({
        method: 'get',
        url: `freeday/change-foreman-status/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Státusz sikeresen állítva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.runFilter()
      })
    },
    edit(id) {
      this.selected_id = id
      this.update_free_day_request = this.rows.find(item => item.id === id)
      this.$refs.update_free_day_request.show()
    },
    changeLeader(id) {
      axios({
        method: 'get',
        url: `freeday/change-leader-status/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Státusz sikeresen állítva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.runFilter()
      })
    },
    getArchiveNameById(id) {
      const archive = this.archiveStatus.find(item => item.value === id)
      return archive.text
    },
    getEmployeeNameById(id) {
      const employee = this.employees.find(item => item.id === id)
      return employee.name
    },
    updateFreeDay() {
      axios({
        method: 'put',
        url: `freeday/update/${this.selected_id}`,
        data: this.update_free_day_request,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Szabadnap frissítve lett',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.update_free_day_request.type = ''
        this.update_free_day_request.foreman_id = ''
        this.update_free_day_request.employee_id = ''
        this.update_free_day_request.start_date = ''
        this.update_free_day_request.payment = 'Havi'
        this.update_free_day_request.end_date = ''
        this.update_free_day_request.comment = ''
        this.update_free_day_request.timeStart = ''
        this.update_free_day_request.timeEnd = ''
        this.$refs.update_free_day_request.hide()
        this.runFilter()
      })
    },
    createFreeDay() {
      axios({
        method: 'post',
        url: 'freeday/create',
        data: this.free_day_request,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        if (res.data === 'error') {
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Szabadnap már van erre a napra rögzítve',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Szabadnap rögzítve lett',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.free_day_request.type = ''
        this.free_day_request.foreman_id = ''
        this.free_day_request.employee_id = ''
        this.free_day_request.start_date = ''
        this.free_day_request.end_date = ''
        this.free_day_request.payment = 'Havi'
        this.free_day_request.comment = ''
        this.free_day_request.timeStart = ''
        this.free_day_request.timeEnd = ''
        this.$refs.free_day_request.hide()
        this.runFilter()
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Minden mező kitöltése kötelező',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      })
    },
    checkRole(role) {
      return JSON.parse(localStorage.getItem('user')).role.includes(role)
    },
    openFreeDayRequest() {
      this.$refs.free_day_request.show()
    },
    getEmployeeWithOutAll() {
      axios({
        method: 'get',
        url: 'employee/list/only_name_and_id',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.employees_create = res.data
      })
    },
    getEmployees() {
      axios({
        method: 'get',
        url: 'employee/list/only_name_and_id',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.employees = res.data
        this.employees.push({
          id: '%',
          name: 'Minden alkalmazott',
        })
      })
    },
    archive(id) {
      axios({
        method: 'get',
        url: `freeday/archive/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Státusz sikeresen állítva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.runFilter()
      })
    },
    deleteFreeday(id) {
      Swal.fire({
        title: 'Bizotsan törölni szeretnéd?',
        showDenyButton: false,
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-danger mr-1',
        },
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'Törlés',
        cancelButtonText: 'Mégsem',
      }).then(result => {
        if (result.isDenied) {
          return null
        }
        if (result.isConfirmed) {
          axios({
            method: 'delete',
            url: `freeday/delete/${id}`,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sikeresen törölve',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.runFilter()
          })
        }
      })
    },
    getFreeDays() {
      axios({
        method: 'get',
        url: 'freeday/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.rows = res.data
      })
    },
    getForemanList() {
      axios({
        method: 'get',
        url: 'role/list/foreman_freeday',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.foreman_list = res.data
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
